import React from 'react';
import './App.css';
import ListCreditCards from "./view/ListCreditCards";
import {Navigate, Route, Routes} from "react-router-dom";
import {Card} from "react-bootstrap";
import Footer from "./component/Footer";
import Header from "./component/Header";
import {useConfig} from "./context/configContext";
import {Brand} from "./model/Brand";
import BrowserVersionMessage from "./component/BrowserVersionMessage";
import VersionDetails from "./view/VersionDetails";
import ThirdPartyForm from "./view/ThirdPartyForm";
import AddCardComplete from "./view/AddCardComplete";
import RegisterCreditCardFeatureToggled from "./view/RegisterCreditCardFeatureToggled";

function App() {

  const { brand,configInitialised, handleRedirect, postGoogleAnalytics } = useConfig();

  return (
      <>
          {
              configInitialised && <div className={`App ${brand === Brand.NEW_WORLD ? "nw-theme" : "pns-theme"}`}>
                  <Card className="cardContainer">
                      <Header brand={brand} handleRedirect={handleRedirect} postGoogleAnalytics={postGoogleAnalytics}/>
                      <Card.Body>
                          <BrowserVersionMessage>
                              <Routes>
                                  <Route path="/" element={
                                      <ListCreditCards/>
                                  } />
                                  <Route path="/register" element={
                                      <RegisterCreditCardFeatureToggled/>
                                  } />
                                  <Route path="/secure-verification" element={
                                      <ThirdPartyForm/>
                                  } />
                                  <Route path="/completed/:notificationId" element={
                                      <AddCardComplete/>
                                  } />
                                  <Route path="/version" element={
                                    <VersionDetails />
                                  } />
                                  <Route path="/*" element={
                                      <Navigate to="/" replace/>
                                  } />
                              </Routes>
                          </BrowserVersionMessage>
                      </Card.Body>
                      <Footer />
                  </Card>
              </div>
          }
      </>
  );
}

export default App;
