// @flow
import {Brand} from "../model/Brand";
import Button from "react-bootstrap/Button";
import SpinnerButton from "./SpinnerButton";
import React from "react";
import Modal from "react-bootstrap/Modal";

const DeleteCardModal = (props) => {
    const {showModal, handleClose, brand, handleDelete, processingCard} = props;

    return (
        <Modal
            show={showModal}
            animation={true} // For getting rid of findDOMNode console error
            onHide={handleClose}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={`center ${brand=== Brand.NEW_WORLD ? "nw-theme" : "pns-theme"}`}>
            <Modal.Header closeButton />
                <Modal.Body>
                    Are you sure you want to delete this card?
                </Modal.Body>
                <Modal.Footer className="center">
                <Button onClick={handleClose} variant="outline-primary" style={{width: '40%', padding: '.4rem'}}>No</Button>
                {
                    !processingCard && <Button onClick={handleDelete} variant="primary" style={{width: '40%', padding: '.5rem 0rem .5rem 0rem'}}>Yes, remove</Button>
                }
                {
                    processingCard && <SpinnerButton style={{width: '40%', padding: '.5rem .5rem'}} variant="primary" text="Deleting..." />
                }
            </Modal.Footer>
        </Modal>
);
}
export default DeleteCardModal;