import React from 'react';
import {useConfig} from "../context/configContext";
import RegisterCreditCard3dsFlow from "./RegisterCreditCard3dsFlow";
import RegisterCreditCard from "./RegisterCreditCard";
import {devLogger} from "../util/loggingUtils";
const RegisterCreditCardFeatureToggled = () => {
    const { resolveFeatureFlag } = useConfig();
    const threeDSEnabled = resolveFeatureFlag("enable_3ds_secure_flow");
    devLogger("3DS Enabled: " + threeDSEnabled);
    return(
        <>
            {threeDSEnabled ?
                (<RegisterCreditCard3dsFlow/>) :
                (<RegisterCreditCard/>)
            }

        </>
    );
}

export default RegisterCreditCardFeatureToggled