import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {getAddCardStatus} from "../client/paymentEdge";
import LoadingMessage from "../component/LoadingMessage";
import AlertMessage from "../component/AlertMessage";
import Button from "react-bootstrap/Button";
import delay from "delay";
import {useConfig} from "../context/configContext";
import {getConfiguration} from "../config/config";

const ThirdPartyForm = () => {
    const { state } = useLocation();
    const formHtml = state?.thirdPartyForm;
    const notificationId = state?.notificationId;
    const { apiToken, resolveFeatureFlag} = useConfig();
    const edgeStatusFeatureFlag = resolveFeatureFlag("enable_edge_get_card_status");
    const [formFound, setFormFound] = useState(true);
    const [message, setMessage] = useState("")
    const [messageLevel, setMessageLevel] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        const checkForForm  = async () => {
            if (formHtml?.length) {
                if (document.forms.length === 1 && document.forms[0]) {
                    document.forms[0].submit();
                } else {
                    setFormFound(false);
                    /*
                    * * Test card used is not setup for 3DS - No card added
                    * * Test card used is frictionless flow - Card added
                    * This could occur if we hit a merchant account not configured for 3DS and we
                    * instead received the return_url html. This should only really occur using test cards
                    * not setup to hit 3DS merchant accounts.
                    * Sadly we cannot access the redirect to go there as the browser automatically follows the
                    * redirects, and we receive the content of that, which is the completed page. Not really easily
                    * rendered. If we want to handle this better it needs to be done on the server, however we want
                    * to go directly to Paymark, so not possible. This is likely why Paymark returns a form to submit
                    * as a redirect. This will also occur when frictionless flow is used.
                    */
                    try {
                        await delay(getConfiguration().cardStatusDelay);
                        const result = await getAddCardStatus(notificationId, apiToken, edgeStatusFeatureFlag);
                        setMessage(result.message)
                        setMessageLevel(result.messageLevel)
                        setLoading(false);
                        navigate("/");
                    } catch (error: any) {
                        setMessage(error.message)
                        setMessageLevel(error.messageLevel)
                        setError(true);
                        setLoading(false);
                    }
                }
            } else {
                navigate("/")
            }
        }
        checkForForm()

    }, [formHtml, navigate, notificationId, apiToken, edgeStatusFeatureFlag]);
    return (
        <>
            {formFound ?
                <h2 className="title" style={{fontWeight: 900}}>Redirecting to secure verification</h2> :
                <h2 className="title" style={{fontWeight: 900}}>Checking card status</h2>
            }
            { loading &&  <LoadingMessage />}
            {
                message &&
                <>
                    <AlertMessage message={message} messageLevel={messageLevel}/>
                    {error &&
                        <div className="mt-4 mb-4 center">
                            <Button variant="primary"
                                    onClick={() => navigate("/")}>
                                OK
                            </Button>
                        </div>
                    }
                </>
            }
            {
                formFound &&
                <div id="redirectHtml"  data-testid="thirdPartyHtml"
                     dangerouslySetInnerHTML={{__html: formHtml}}
                />
            }
        </>
    );
}
export default ThirdPartyForm;